<template>
	<div class="Group bg-white px-1 align-justify">
			<Select v-model="taskSelect" filterable size="large" style="width: 15rem; margin-right: 0.5rem;" placeholder="选择任务">
				<Option v-for="(titem,tkey) in taskOption" :key="tkey" :value="sitem.taskId">{{sitem.name}}</Option>
			</Select>
			<!-- 根据任务异步加载量表和部门 -->
			<Select v-model="scaleSelect" filterable size="large" style="width: 15rem; margin-right: 0.5rem;" placeholder="选择量表">
				<Option v-for="(sitem,skey) in scaleOption" :key="skey" :value="sitem.key">{{sitem.label}}</Option>
			</Select>
			<Cascader :data="departCascader" change-on-select clearable size="large" placeholder="选择部门" style="width:20rem; margin-right: 0.5rem; display: inline-block;"></Cascader>
			<Button type="default" size="large" class="mr-1">批量打印</Button>
			<Button type="default" size="large" class="mr-1">团体报告</Button>
			<!-- 团体报告会单独出模板 -->
			<Dropdown placement="bottom-end">
				<Button icon="md-more" size="large"></Button>
				<DropdownMenu slot="list">
					<DropdownItem>打印多表报告</DropdownItem>
					<DropdownItem>批量删除数据</DropdownItem>
					<DropdownItem>批量导出数据</DropdownItem>
					<DropdownItem>批量导出报告</DropdownItem>
					<DropdownItem>批量生成报告</DropdownItem>	
				</DropdownMenu>
			</Dropdown>
		<Table border stripe ref="groupScaleTable" :columns="tColumns" :data="tData" :height="theight" class="mt-1">
			<div slot-scope="{row, index }" slot="action">
				<Button type="primary" size="small" style="margin-right: 0.25rem" @click="show(index)">报告</Button>
				<Button size="small" @click="remove(index)">删除</Button>
			</div>
		</Table>
		<Page :total="recordTotal" :page-size="20" show-total show-elevator class="p-1 align-right" />
	</div>
</template>

<script>
	import tData from '../json/scalesDossiers_tData.json'
	import expandRow from '@/components/expand_scalesDossiers.vue'
	export default {
		name: 'Group',
		data() {
			return {
				tData,
				theight: window.innerHeight - 275,
				scaleSelect: '',
				scaleOption: [],
				taskSelect: '',
				taskOption: [],
				departCascader: [],
				recordTotal:0,
				tColumns: [{
					type: 'selection',
					width: 35,
					align: 'center'
				},{
					type: 'index',
					title:'序号',
					width: 45,
					align: 'center'
				}, {
					title: '被测号',
					key: 'userId',
					minWidth: 120
				}, {
					title: '姓名',
					key: 'userName',
					minWidth: 120
				}, {
					title: '量表',
					key: 'scaleName',
					minWidth: 240,
					ellipsis: true
				}, {
					title: '性别',
					key: 'gender',
					render:(h,params) =>{
						return h ('span',this.myjs.getGender(params.row.gender));
					},
					minWidth: 60
				}, {
					title: '年龄',
					key: 'age',
					render: (h, params) => {
						let age = this.myjs.getAge(params.row.birthday, params.row.submitTime);
						return h('span', age)
					},
					minWidth: 70,
					sortable: true
				}, {
					title: '主用户',
					key: 'relevance',
					minWidth: 120
				}, {
					title: '测评时间',
					key: 'submitTime',
					minWidth: 100,
					render: (h, params) => {
						let dt = this.myjs.subDate(params.row.submitTime);
						return h('span', dt)
					},
					sortable: true,
					sortType: 'desc'
				},{
					type: 'expand',
					width: 45,
					title:'其它',
					render: (h, params) => {
						return h(expandRow, {
							props: {
								row: params.row
							}
						})
					}
				}, {
					title: '操作',
					slot: 'action',
					width: 102
				}]	
			}
		},
		mounted() {
			window.addEventListener('resize', () => this.theight = window.innerHeight - 275, false)
		}
	}
</script>

<style>
</style>
