<template>
	<div class="expand-scalesDossiers">
		<Row class="expand-row" :gutter="8">
			<i-col span="6">
				<span class="expand-key">婚姻：</span>
				<span class="expand-value">{{ row.marriage }}</span>
			</i-col>
			<i-col span="6">
				<span class="expand-key">学历：</span>
				<span class="expand-value">{{ row.education }}</span>
			</i-col>
			<i-col span="6">
				<span class="expand-key">职业：</span>
				<span class="expand-value">{{ row.professional }}</span>
			</i-col>
			<i-col span="6">
				<span class="expand-key">手机号：</span>
				<span class="expand-value">{{ row.bindPhone }}</span>
			</i-col>
			</Row>
			<Row class="expand-row" :gutter="8">
			<i-col span="12">
				<span class="expand-key">单位：</span>
				<span class="expand-value">{{ row.organ }}</span>
			</i-col>
			<i-col span="12">
				<span class="expand-key">部门：</span>
				<span class="expand-value">{{ row.depart}}</span>
			</i-col>
		</Row>
	</div>
</template>

<script>
	export default {
		props: {
			row: Object
		}
	};
</script>

<style>
</style>
